import { defineStore } from 'pinia'
import {
  ApiNames,
  httpGetAuth,
  httpPost,
  httpGetByUrl,
  httpGetAuthFileDownload,
  httpDelete,
  httpGetAws,
} from '@/libs/httpHelper'
import { useUserStore } from './user.js'
import { httpGet } from '../libs/httpHelper.js'

export const useConfigurationStore = defineStore({
  id: 'configurationState',
  // arrow function recommended for full type inference
  state: () => {
    return {
      configuration: {
        ID: '',
        Info: '',
      },
      configurationAccountTypes: [
        {
          ID: '',
          Name: '',
          Descriprion: '',
        },
      ],
      configurationsAws: [
        {
          ID: '',
          ConfigurationID: '',
          ConfigurationAccountTypeID: '',
          Count: 0,
          RuntimeInMonth: 0,
          StartDate: '',
          EndDate: '',
          FleetNormal: '',
          FleetReadable: '',
          CreatedBy: '',
          Created: '',
          ModifiedBy: '',
          Modified: '',
        },
      ],
      awsStacks: [],
    }
  },
  persist: true,
  getters: {
    // ...
  },
  actions: {
    resetState() {
      this.configuration = {
        ID: '',
        Info: '',
      }
      this.configurationAccountTypes = [
        {
          ID: '',
          Name: '',
          Descriprion: '',
        },
      ]
      this.configurationsAws = [
        {
          ID: '',
          ConfigurationID: '',
          ConfigurationAccountTypeID: '',
          Count: 0,
          RuntimeInMonth: 0,
          StartDate: '',
          EndDate: '',
          FleetNormal: '',
          FleetReadable: '',
          CreatedBy: '',
          Created: '',
          ModifiedBy: '',
          Modified: '',
        },
      ]
      this.awsStacks = []
    },
    async getConfiguration(configID, token) {
      const params = { id: configID }

      var response = await httpGetAuth(ApiNames.configuration, params, {}, token)
      this.configuration = response.data[0]
      return response
    },
    async createConfiguration(info, companyID, token) {
      var dataObj = {
        Info: info,
        CompanyID: companyID,
      }
      const headerConfig = {
        headers: { Authorization: `Bearer ${token}` },
      }
      var response = await httpPost(ApiNames.createconfiguration, dataObj, headerConfig)

      return response
    },

    async getConfigurationAccountTypes(token) {
      var response = await httpGetAuth(ApiNames.configurationaccounttypes, {}, {}, token)
      this.configurationAccountTypes = response.data
      return response
    },

    async getConfigurationHistoryCompanyHasProduct(companyHasProductID, token) {
      const params = { id: companyHasProductID }

      var response = await httpGetAuth(ApiNames.configurationhistory_companyhasproduct, params, {}, token)

      return response
    },

    async getConfigurationHistoryConfigurationID(configurationID, token) {
      const params = { id: configurationID }

      var response = await httpGetAuth(ApiNames.configurationhistory_configuration, params, {}, token)

      return response
    },

    async getConfigurationsAws(configID, token) {
      const params = { id: configID }

      var response = await httpGetAuth(ApiNames.configuration_awsconfigurationid, params, {}, token)
      if (response.data === '') {
        this.configurationsAws = []
      } else {
        this.configurationsAws = response.data
      }
      return response
    },

    async getAwsStacks(token) {
      var response = await httpGetAuth(ApiNames.awsstacks, {}, {}, token)

      if (response.status === 200) this.awsStacks = response.data

      return response
    },

    async createConfigurationAWS(object, token) {
      const headerConfig = {
        headers: { Authorization: `Bearer ${token}` },
      }
      var response = await httpPost(ApiNames.createconfigurationaws, object, headerConfig)

      return response
    },

    async CreateContactHasAwsUserOld(obj, token) {
      const headerConfig = {
        headers: { Authorization: `Bearer ${token}` },
      }
      var response = await httpPost(ApiNames.createcontacthasawsuserold, obj, headerConfig)

      return response
    },

    async CreateAwsUser(obj, token) {
      const headerConfig = {
        headers: { Authorization: `Bearer ${token}` },
      }
      var response = await httpPost(ApiNames.createawsuser, obj, headerConfig)

      return response
    },
    async createAwsUserOld(obj, token) {
      const headerConfig = {
        headers: { Authorization: `Bearer ${token}` },
      }
      var response = await httpPost(ApiNames.createawsuserold, obj, headerConfig)

      return response
    },

    async updateConfigurationAws(config, token) {
      const headerConfig = {
        headers: { Authorization: `Bearer ${token}` },
      }
      var response = await httpPost(ApiNames.updateconfiguration_aws, config, headerConfig)
      return response
    },

    async updateConfiguration(configuration, token) {
      const headerConfig = {
        headers: { Authorization: `Bearer ${token}` },
      }
      var response = await httpPost(ApiNames.updateconfiguration, configuration, headerConfig)
      return response
    },

    async getConfigurationAwsHasContactConfigurationID(configAwsID, token) {
      const params = { id: configAwsID }

      var response = await httpGetAuth(ApiNames.configurationawshascontact_configuration, params, {}, token)

      return response
    },
    async getConfigurationAwsHasContactPrimary(configurationAwsId, contactId, token) {
      const params = {
        configurationAwsId: configurationAwsId,
        contactId: contactId,
      }

      var response = await httpGetAuth(ApiNames.configurationawshascontact_primary, params, {}, token)

      return response
    },
    async createConfigurationAwsHasContactManyContacts(obj, token) {
      const creatorId = obj.creatorId
      const configurationAwsId = obj.configurationAwsId
      const contactIds = obj.contactIds

      const headerConfig = {
        headers: { Authorization: `Bearer ${token}` },
        params: {
          creatorId: creatorId,
          configurationAwsId: configurationAwsId,
        },
      }
      var response = await httpPost(ApiNames.createconfigurationawshascontact_manycontacts, contactIds, headerConfig)
      return response
    },

    async updateConfigurationAwsHasContact(dataObj, token) {
      const headerConfig = {
        headers: { Authorization: `Bearer ${token}` },
      }
      var response = await httpPost(ApiNames.updateconfigurationawshascontact, dataObj, headerConfig)
      return response
    },

    async deleteConfigurationHistoryByConfiguration(configID, token) {
      const params = {
        id: configID,
      }
      const header = { Authorization: `Bearer ${token}` }
      var response = await httpDelete(ApiNames.deleteconfigurationhistory_configuration, params, header)

      return response
    },

    async deleteConfigurationAws(configID, token) {
      const params = {
        id: configID,
      }
      const header = { Authorization: `Bearer ${token}` }
      var response = await httpDelete(ApiNames.deleteconfigurationaws, params, header)

      return response
    },
  },
})
