<template>
  <div class="md-layout scroll-behavior">
    <div class="md-layout-item md-size-66 md-medium-size-66 md-small-size-100 md-xsmall-size-100">
      <loader :loader="loader" />
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-primary">
          <div class="card-icon">
            <md-icon>list</md-icon>
          </div>
          <div class="md-title">{{ $t('awsAccesses') }}</div>
          <div class="md-subtitle">{{ this.companyStore.company.Name }}</div>
        </md-card-header>
        <md-card-content>
          <md-table v-if="awsAccounts.length > 0" v-model="awsAccounts">
            <md-table-row slot="md-table-row" slot-scope="{ item }">
              <md-table-cell md-sort-by="id" :md-label="$t('name')">
                <strong>{{ item.FleetReadable }}</strong>
              </md-table-cell>

              <md-table-cell md-sort-by="id" :md-label="$t('startDate')">
                {{ getReadableDate(item.StartDate) }}
              </md-table-cell>

              <md-table-cell md-sort-by="id" :md-label="$t('endDate')">
                {{ getReadableDate(item.EndDate) }}
              </md-table-cell>

              <md-table-cell :md-label="$t('accessActive')">
                <md-icon class="fs-24" v-if="getActive(item.EndDate)">close</md-icon>
                <md-icon class="fs-24" v-else>done</md-icon>
              </md-table-cell>

              <md-table-cell class="fixed-width" :md-label="$t('controls')">
                <div class="controls">
                  <md-button class="md-just-icon md-primary md-simple" @click.native="handleEditAwsAccount(item)">
                    <md-icon>edit</md-icon>
                    <md-tooltip md-direction="bottom">{{ $t('edit') }}</md-tooltip>
                  </md-button>

                  <md-button
                    v-if="allow('{2924a011-9a7e-47b0-9b45-d898ca00eae8}')"
                    class="md-just-icon md-danger md-simple"
                    @click.native="handleDeleteAwsAccount(item)"
                  >
                    <md-icon>close</md-icon>
                    <md-tooltip md-direction="bottom">{{ $t('delete') }}</md-tooltip>
                  </md-button>
                </div>
              </md-table-cell>
            </md-table-row>
          </md-table>

          <div v-else class="md-center pt-10">
            <h3>{{ $t('noConfigFound') }}</h3>
          </div>
          <div class="text-right pt-10">
            <md-button v-if="allow('{8e19c7ed-d899-44db-8e5c-83c0ecec8e3d}')" id="submit" class="md-raised md-primary md-round" @click="addAwsConfig()">
              {{ $t('create') }}
            </md-button>
          </div>
        </md-card-content>
      </md-card>
    </div>
  </div>
</template>
<script>
import { useCompanyStore } from '@/stores/company'
import { useUserStore } from '@/stores/user'
import { useConfigurationStore } from '@/stores/configuration'
import Swal from 'sweetalert2'
import { permissionMixin } from '../RoleManagement/permissionMixin'

export default {
  mixins: [permissionMixin],
  data() {
    return {
      productIDCloud: '{faeb2319-1fb3-4fb8-a5ea-d3250f5ccccd}',
      selected: {},
      awsAccounts: [],
      loader: false,
    }
  },
  async beforeMount() {
    this.loader = true
    await this.loadData()
    this.loader = false
  },
  methods: {
    getActive(date) {
      return new Date() > new Date(date)
    },
    getReadableDate(input) {
      const date = new Date(input)
      let dateString = date.toLocaleDateString()
      return dateString
    },
    addAwsConfig() {
      this.$router.push({ name: 'Create Aws Config' })
    },
    onSelect(item) {
      this.selected = item
      this.configurationStore.configuration = item
      this.$router.push({
        name: 'Aws Account',
      })
    },
    isEmptyObject(obj) {
      return Object.keys(obj).length === 0
    },
    handleEditAwsAccount(item) {
      this.selected = item
      this.configurationStore.configuration = item
      this.$router.push({
        name: 'Aws Account',
      })
    },
    handleDeleteAwsAccount(item) {
      Swal.fire({
        icon: 'question',
        title: this.$t('delete'),
        html: this.$t('deleteAwsConfigText').replace('%name%', item.FleetReadable),
        showCancelButton: true,
        cancelButtonText: this.$t('cancel'),
      }).then((result) => {
        if (!result.isConfirmed) return

        Swal.fire({
          icon: 'warning',
          title: this.$t('deleteTitle'),
          html: this.$t('deleteText'),
          showCancelButton: true,
          cancelButtonText: this.$t('cancel'),
        }).then((result) => {
          if (!result.isConfirmed) return
          this.deleteAwsAccount(item.ID)
        })
      })
    },
    async deleteAwsAccount(id) {
      var res = await this.configurationStore.deleteConfigurationAws(id, this.userStore.token)
      if (res.status !== 200) {
        Swal.fire({
          icon: 'error',
          title: this.$t('error'),
          html: res.Message,
        })
      } else {
        Swal.fire({
          icon: 'success',
          title: this.$t('success'),
          html: this.$t('deleteAwsAccSucc'),
        })
        this.loadData()
      }
    },
    async loadData() {
      const product = this.companyStore.productData.companyHasProduct.find((p) => p.ProductID === this.productIDCloud)
      if (!product || this.isEmptyObject(product)) this.$router.push({ name: 'Company Products' })
      const token = this.userStore.token
      // laden und speichern der aws accounttypen
      var resTypes = await this.configurationStore.getConfigurationAccountTypes(token)
      var resConfig = await this.configurationStore.getConfiguration(product.ConfigurationID, token)
      var resConfigAws = await this.configurationStore.getConfigurationsAws(product.ConfigurationID, token)
      var resAwsStacks = await this.configurationStore.getAwsStacks(token)
      this.awsAccounts = this.configurationStore.configurationsAws
      this.tableData = this.awsAccounts
    },
  },
  computed: {},
  setup() {
    const companyStore = useCompanyStore()
    const userStore = useUserStore()
    const configurationStore = useConfigurationStore()
    return { companyStore, userStore, configurationStore }
  },
}
</script>

<style scoped>
.md-checkbox {
  margin: unset !important;
}
.md-center {
  text-align: center;
}
.controls {
  display: flex;
  justify-content: flex-end;
}
.fixed-width {
  width: 80px !important;
}
.md-table-head:last-child:not(:first-child) .md-table-head-label {
  padding-right: 0 !important;
}
.pt-10 {
  padding-top: 10px;
}
.fs-24 {
  font-size: 1.1rem !important;
}
.md-table-cell {
  padding: 5px 0 !important;
  height: 40px !important;
}
</style>
