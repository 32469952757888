import { render, staticRenderFns } from "./MdesignOverview.vue?vue&type=template&id=511e93b6&scoped=true"
import script from "./MdesignOverview.vue?vue&type=script&lang=js"
export * from "./MdesignOverview.vue?vue&type=script&lang=js"
import style0 from "./MdesignOverview.vue?vue&type=style&index=0&id=511e93b6&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "511e93b6",
  null
  
)

export default component.exports